import React, { useState, useEffect } from 'react';
import CleanPlace from '../assets/Images/CleanPlace.jpg';
import Logo from '../assets/Images/logo.png';
// import SuperDurty from '../assets/Images/SuperDurty.jpg';
// import SuperCleanR from '../assets/Images/SuperCleanR.jpg';
// import SuperClean from '../assets/Images/SuperClean.jpg';
// import Patio from '../assets/Images/patio.jpg';
// import Oven from '../assets/Images/oven.jpeg';
// import Grill from '../assets/Images/Grill.jpg';
// import DurtyWindow from '../assets/Images/DurtyWindow.jpg';
// import WindowClean from '../assets/Images/WindowClean.jpg';
// import DurtyToiler from '../assets/Images/DurtyToiler.jpg';
// import DurtyShawer from '../assets/Images/DurtyShawer.jpg';
// import DurtyRoof from '../assets/Images/DurtyRoof.jpg';
// import DurtyMetal from '../assets/Images/DurtyMetal.jpg';
// import DurtyMetalR from '../assets/Images/DurtyMetalR.jpg';
// import DurtyKitchen from '../assets/Images/DurtyKitchen.jpg';
// import DurtyFloor from '../assets/Images/DurtyFloor.jpg';
// import DurtyBath from '../assets/Images/DurtyBath.jpg';
// import ConcreteClean from '../assets/Images/Concrete-cleaning.jpg';
// import CleanWay from '../assets/Images/CleanWay.jpg';
// import CleanR from '../assets/Images/CleantR.jpg';
// import CleanStuff from '../assets/Images/CleanStuff.jpg';
// import CleanShawer from '../assets/Images/CleanShawer.jpg';
// import CleanRoof from '../assets/Images/CleanRoof.jpg';
// import CleanMetal from '../assets/Images/CleanMetal.jpg';
// import CleanMetalR from '../assets/Images/CleanMetalR.jpg';
// import CleanKitchen from '../assets/Images/CleanKitchen.jpg';
// import CleanFloor from '../assets/Images/CleanFloor.jpg';
// import CleanBath from '../assets/Images/CleanBath.jpg';
import { UilCalendarAlt } from '@iconscout/react-unicons';
import FooterPage from '../components/FooterPage';
import BackToTopButtom from '../components/BackToTopButtom';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import ChatNow from '../components/ChatNow';
import { ClipLoader } from "react-spinners";
import { storage } from "../firebase";
import { listAll, getDownloadURL, ref, getMetadata } from "firebase/storage";
import LazyLoad from 'react-lazyload';


const Contact = () => {
  const [loading, setLoading] = useState(true);
  const [images, setImages] = useState([]);

  // useEffect(() => {
  //   setLoading(true);
  //   setTimeout(() => {
  //     setLoading(false);
  //   }, 2000);
  //   const storageRef = ref(storage, 'Images/');
  //   // List all files in the folder
  //   listAll(storageRef)
  //     .then((res) => {
  //       // Get the download URL for each file
  //       return Promise.all(res.items.map((item) => getDownloadURL(item)));
  //     })
  //     .then((urls) => {
  //       // Save the URLs to the state
  //       setImages(urls);
  //       setLoading(false);
  //     })
  //     .catch((error) => {
  //       console.error('Error:', error);
  //       setLoading(false);
  //     });
  // }, []);

  useEffect(() => {
    setLoading(true);
    const storageRef = ref(storage, 'Images/');
    // List all files in the folder
    listAll(storageRef)
      .then((res) => {
        // Fetch both the download URL and metadata for each file
        return Promise.all(res.items.map((item) =>
          Promise.all([getDownloadURL(item), getMetadata(item)])
        ));
      })
      .then((results) => {
        // Map each result to an object containing both the URL and the last updated timestamp
        const imagesWithMetadata = results.map(([url, metadata]) => ({
          url,
          lastUpdated: metadata.updated,
        }));
        // Sort the images by the last updated timestamp in descending order
        imagesWithMetadata.sort((a, b) => new Date(b.lastUpdated) - new Date(a.lastUpdated));
        // Save the sorted images to the state
        setImages(imagesWithMetadata.map(img => img.url));
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error:', error);
        setLoading(false);
      });
  }, []);
  return (
    <div className=" mx-auto">
      {loading ? <div className="flex justify-center items-center h-screen">
        <ClipLoader color={"#f84444"} loading={loading} size={60} /></div> :
        <>
          <img src={CleanPlace} className='absolute w-full h-96' alt='clean place' />
          <div className=" w-full h-96 bg-black opacity-50"></div>
          <div className="absolute top-1/3 left-2/4 text-4xl font-bold my-10 text-[#ffffff] ">
            <div className='flex justify-center'>
              <h1 className="text-5xl font-bold text-center my-8 flex justify-center">Gallery</h1>
            </div>
          </div>
          <div className='flex justify-center'>
          </div>
          <div className="flex flex-col sm:flex-row justify-center bg-gradient-color mb-5">
            <div className="flex justify-center items-center">
              <div className="">
                <p className="text-center text-4xl font-bold px-1 py-6 my-4 first-letter:text-5xl">Our Work</p>
                <Carousel autoPlay infiniteLoop useKeyboardArrows showStatus={false} showThumbs={false} showIndicators={false} transitionTime={1000} swipeable stopOnHover interval={6000} swipeScrollTolerance={5}>
                  {!loading && images.map((url, index) => (
                    <LazyLoad key={index} height={550} offset={100} placeholder={<p>Loading...</p>}> {/* Wrap each image with LazyLoad */}
                      <div className="">
                        <img src={url} className='rounded-md' style={{ width: '570px', height: '550px' }} alt='From Firebase' />
                        <p className="absolute left-1/3 bottom-0 bg-opacity-30 bg-black text-white font-light p-2 ">Fabiana Fabulous Cleaning Service LLC</p>
                      </div>
                    </LazyLoad>
                  ))}
                </Carousel>
              </div>
            </div>
          </div>
          <h1 className="text-4xl bg-gradient-colors text-[#ffffff] font-serif font-bold text-center  px-16 py-12 ">Refer us and get 15% off on your next cleaning!</h1>
          <div className="relative">
            <img src={CleanPlace} className='md:w-full md:h-96 w-full h-96' alt='clean place ' />
            <div className="absolute inset-0 bg-black opacity-60 "></div>
            <img src={Logo} className='absolute md:top-1/2 -top-2 md:left-1/3 md:transform md:-translate-x-1/2 md:-translate-y-1/2 w-68 h-64 m-5 ml-7 md:ml-0' alt='Fabiana Fabulous Cleanig Services LLC ' />
            <p className='md:text-center text-[#ffffff]  text-2xl md:absolute md:top-1/2 md:left-2/3 md:transform md:-translate-x-3/4 md:-translate-y-1/2 
                absolute top-1/2 mt-12 md:mt-0 ml-5 rigth-1/2  '>WE MAKE YOUR HOME LOOK LIKE NEW AGAIN</p>
            <button type="button" className="md:flex justify-center items-center 
                    md:absolute md:top-2/3 md:left-2/3 md:transform md:-translate-x-3/4 md:-translate-y-1/2 text-lg md:mb-8 h-10 md:px-10 md:py-5 border rounded-md bg-[#43b0f1] hover:bg-gradient-colors
                    absolute top-1/2 mt-32 md:mt-0 ml-5 md:-ml-5 px-2 py-1 ">
              <a href="tel:425-829-6092" className=" md:text-center text-[#ffff] flex items-center">
                <UilCalendarAlt className="mr-2 button-animate" size="20" />  Schedule Today!
              </a>
            </button>
          </div>
          <FooterPage />
          <div className='p-2'>
            <BackToTopButtom />
          </div>
          <div className='p-2'>
            <ChatNow />
          </div>
        </>
      }
    </div>
  );
};

export default Contact;