import React, { useState } from 'react';
import axios from 'axios';
import ReCAPTCHA from "react-google-recaptcha";

const Request = () => {
    const [recaptchaValue, setRecaptchaValue] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        email: '',
        projectDescription: ''
    });
    
    const [errors, setErrors] = useState({});
    const handleChange = (event) => {
        setFormData({ ...formData, [event.target.name]: event.target.value });
    };

    const validateForm = () => {
        let formErrors = {};
        if (!formData.name) formErrors.name = "Name is required";
        if (!formData.phone) formErrors.phone = "Phone is required";
        if (!formData.email) formErrors.email = "Email is required";
        if (!formData.projectDescription) formErrors.projectDescription = "Job description is required";

        setErrors(formErrors);

        // If the errors object is empty, the form is valid
        return Object.keys(formErrors).length === 0;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!validateForm()) {
            return;
        }

        // Add the reCAPTCHA token to the form data
        formData.recaptcha = recaptchaValue;

        try {
            const message = `Hi Fabiana's, you have new estimate request by:\nName: ${formData.name}\nPhone: ${formData.phone}\nEmail: ${formData.email}\nProject Description: ${formData.projectDescription}`;
            await axios.post('https://api.twilio.com/2010-04-01/Accounts/AC4852e25a8d04b2707f33b8a6b68e68a8/Messages.json', {
                To: '4258296092',
                From: '+18885598412',
                Body: message
            }, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': 'Basic QUM0ODUyZTI1YThkMDRiMjcwN2YzM2I4YTZiNjhlNjhhODpiOTJhMDVjMGM2MTc0N2FhOGQ3ZTNlMTJmY2Q3MDM0Mg=='
                }
            });
            setIsSubmitted(true);
            setFormData({ name: '', phone: '', email: '', projectDescription: '' });
            setTimeout(() => {
                setIsSubmitted(false);
            }, 5000);
        } catch (error) {
            console.error(error);
            alert('Failed to send message');
        } finally {
            
        }
    };
    const handleRecaptcha = value => {
        setRecaptchaValue(value);
    };
    return (
        <><div className="md:flex justify-center text-center text-4xl font-bold m-10 ">Request A <span className='text-[#ffa600] whitespace-pre'> Free </span> Estimate</div><div className='flex flex-col items-center justify-center mt-10'>
            <form onSubmit={handleSubmit} className='w-full max-w-2xl rounded-md shadow-xl p-3'>
                <div className='mb-4 shadow-md'>
                    <input value={formData.name} onChange={handleChange} className='shadow appearance-none border border-[#92d9f7] rounded-bg-[] w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline' name='name' type='text' placeholder='name' required />
                    {errors.name && <p className='text-red-500 text-xs italic'>{errors.name}</p>}
                </div>
                <div className='mb-4 shadow-md'>
                    <input value={formData.phone} onChange={handleChange} className='shadow appearance-none border border-[#92d9f7] rounded  w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline' name='phone' type='text' placeholder='phone' required />
                    {errors.phone && <p className='text-red-500 text-xs italic'>{errors.phone}</p>}
                </div>
                <div className='mb-4 shadow-md'>
                    <input value={formData.email} onChange={handleChange} className='shadow appearance-none border border-[#92d9f7] rounded-bg-[] w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline' name='email' type='email' placeholder='email' required />
                    {errors.email && <p className='text-red-500 text-xs italic'>{errors.email}</p>}
                </div>
                <div className='mb-6 shadow-md'>
                    <textarea value={formData.projectDescription} onChange={handleChange} className='shadow appearance-none border border-[#92d9f7] rounded-bg-[] w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline' name='projectDescription' placeholder='Job description' required />
                </div>
                <ReCAPTCHA sitekey="6Ldl7WMpAAAAADw6ax33rtFGknxmChmM3_JzaQYj" onChange={handleRecaptcha} className='my-8 flex justify-center' />
                <div className='flex items-center justify-between'>
                    <button className='bg-blue-500 hover:bg-gradient-colors text-white w-full font-bold py-3 px-6 rounded focus:outline-none focus:shadow-outline' type="submit" disabled={!Object.values(formData).some(x => x !== null && x !== '')}>
                        Request Estimate
                    </button>
                </div>
                <div className='text-[#2baaff] text-center my-3'>
                    {isSubmitted && <p>Message sent successfully!</p>}
                </div>
            </form>
        </div></>

    )
}

export default Request