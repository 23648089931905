import React, { useState, useRef, useEffect } from 'react';
import { UilClock, UilCalling, UilBars, UilTimes } from '@iconscout/react-unicons';
import { Link } from 'react-router-dom';
import Logo from '../assets/Images/logo.png';
import { UilCalendarAlt } from '@iconscout/react-unicons';
import Modal from 'react-modal';
import CleaningForm from './CleaningForm';

Modal.setAppElement('#root')

const NavaBar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const node = useRef();
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleClick = e => {
    if (node.current.contains(e.target)) {
      // inside click
      return;
    }
    // outside click
    setIsOpen(false);
  };

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    // add when mounted
    document.addEventListener("mousedown", handleClick);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  return (
    <header className="bg-gray-800   md:px-4 md:py-3 md:items-center" ref={node}>
      <div className="flex flex-col ">
        <div className=" flex items-center justify-between px-4 py-3 sm:p-0">
          <Link to='/' className="flex items-center">
            <img className="h-20 w-auto sm:h-24" src={Logo} alt="Fabiana Fabulous Cleaning Service LLC" />
            <div className="hover:opacity-80">
            <h1 className='title'>Fabiana Fabulous</h1>
              {/* <h1 className="text-[#057dcd] md:text-3xl text-2xl ml-3 font-serif">Fabiana Fabulous </h1> */}
              {/* <h2 className="text-[#ffffff] md:text-2xl text-lg ml-3 font-serif">Cleaning Services LLC</h2> */}
              <h2 className="titletwo">Cleaning Services LLC</h2>
            </div>
          </Link>
          <button onClick={toggleMenu} className=" lg:hidden xl:hidden">
            {isOpen ?
              <UilTimes className='text-4xl ml-2 mt-2 text-[#d99d49]' onClick={() => setIsOpen(!isOpen)} size='40' /> :
              <UilBars className='text-4xl ml-2 mt-2 text-[#d99d49]' onClick={() => setIsOpen(!isOpen)} size='40' />
            }
          </button>
          <div className='md:flex items-center gap-0 hidden'>
            <UilClock size="35" />
            <div className="flex flex-col">
              <div className="mt-1 block px-2 py-1 text-[#057dcd] font-semibold rounded text-sm sm:text-base sm:mt-0 sm:ml-1">OPEN HOURS</div>
              <div className="mt-1 block px-2 py-1 text-white font-semibold rounded hover:bg-gray-700 text-sm sm:text-base sm:mt-0 sm:ml-1">Open from </div>
              <div className="mt-1 block px-2 py-1 text-white font-semibold rounded hover:bg-gray-700 text-sm sm:text-base sm:mt-0 sm:ml-1">7:00am to 6:00pm</div>
            </div>
            <UilCalling size="30" className="call-animate" />
            <div className="flex flex-col">
              <div className="mt-1 block px-2 py-1 text-[#057dcd] font-semibold rounded text-sm sm:text-base sm:mt-0 sm:ml-1">CALL US</div>
              <a href='tel: 425-829-6092' className="mt-1 block px-2 py-1 text-white font-semibold rounded hover:bg-gray-700 text-sm sm:text-base sm:mt-0 sm:ml-1">(425) 829-6092</a>
            </div>
          </div>
        </div>
        <hr />
        <div className='md:flex items-center gap-0 sm:hidden justify-center'>
          <nav className={`px-2 pt-2 pb-4 sm:flex sm:p-3 sm:ml-14 ${isOpen ? 'block bg-gradient-colors' : 'hidden'} sm:block transition ease-in-out duration-300`}>
            <a href='/' className="block px-2 py-1 text-white text-xl font-semibold rounded hover:bg-gray-700 hover:text-[#057dcd] cursor-pointer font-serif">Home</a>
            <a href="/services" className="mt-1 block px-2 py-1 text-white text-xl font-semibold rounded hover:bg-gray-700 hover:text-[#057dcd] sm:mt-0 sm:ml-2 cursor-pointer font-serif">Services</a>
            <a href="/about" className="mt-1 block px-2 py-1 text-white text-xl font-semibold rounded hover:bg-gray-700 hover:text-[#057dcd] sm:mt-0 sm:ml-2 cursor-pointer font-serif">About</a>
            <a href="/contact" className="mt-1 block px-2 py-1 text-white text-xl font-semibold rounded hover:bg-gray-700 hover:text-[#057dcd] sm:mt-0 sm:ml-2 cursor-pointer font-serif">Contact</a>
            <a href="/gallery" className="mt-1 block px-2 py-1 text-white text-xl font-semibold rounded hover:bg-gray-700 hover:text-[#057dcd] sm:mt-0 sm:ml-2 cursor-pointer font-serif">Gallery</a>
            <div className="mt-1 block px-2 py-1">
              <button onClick={openModal} className="button-animate md:text-center text-[#ffff] flex items-center hover:text-[#ffc876]">
                <UilCalendarAlt className="mr-2" size="20" /> Quick Estimate!
              </button>
              <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="Cleaning Form"
                style={{
                  overlay: {
                    backgroundColor: 'rgba(0, 0, 0, 0.75)',
                    transition: 'opacity 0.5s',
                  },
                  content: {
                    top: '50%',
                    left: '50%',
                    right: 'auto',
                    bottom: 'auto',
                    marginRight: '-50%',
                    transform: 'translate(-50%, -50%)',
                    width: '90%',
                    maxWidth: '500px',
                    height: '90%',
                    maxHeight: '500px',
                    padding: '2rem',
                    borderRadius: '10px',
                    boxShadow: '0px 4px 8px rgba(0, 0, 1, )',
                    border: '1px solid #ccc',
                    overflow: 'hidden',
                    background: '#fff',
                    color: '#000',
                    zIndex: '1000',
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                    gap: '1rem',
                    fontFamily: 'sans-serif',
                    fontSize: '1rem',
                    fontWeight: '400',
                    lineHeight: '1.5',
                    letterSpacing: '0.00938em',
                    whiteSpace: 'nowrap',
                    wordSpacing: '0.00938em',
                    textTransform: 'none',
                  },
                }}
              >
                <CleaningForm />
                <button
                  onClick={closeModal}
                  style={{
                    position: 'absolute',
                    top: '1rem',
                    right: '1rem',
                    background: 'transparent',
                    border: 'none',
                    color: '#000',
                    fontSize: '1.5rem',
                    cursor: 'pointer',
                  }}
                >
                  &times;
                </button>
              </Modal>
            </div>
          </nav>
        </div>
      </div>
    </header>
  )
}

export default NavaBar;